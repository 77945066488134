<div style="width: 100vw; height: 100vh; display: grid; justify-items: center;  align-items: center;">
    <div *ngIf="isInErrorState">
        Une erreur à eu lieu lors de l'authentification.<br>
        <a class="home-link" aria-label="Page d'accueil" [routerLink]="['/']">
            Retourner sur la page d'accueil
        </a>
    </div>
    <div *ngIf="!isInErrorState">
        <div style="display: flex; flex-direction: column; align-items: center">
            <div class="lds-ripple"><div></div><div></div></div>
            <div>Connexion en cours...</div>
        </div>
    </div>
</div>
