<mat-sidenav-container style="min-height: 100vh">
    <mat-toolbar color="primary">
        <div class="app-toolbar">
            <h1>
                Naheulbook
            </h1>
            <a class="home-link" aria-label="Page d'accueil" [routerLink]="['/']">
                <mat-icon>home</mat-icon>
            </a>
        </div>
    </mat-toolbar>
    <div class="credit">
        <h2>Crédit</h2>
        <div class="credits">
            <p>
                Les icônes des objets proviennent de <a target="_blank" href="http://game-icons.net/">game-icons.net</a>
            </p>
            <p>
                Les données présentent sur le site proviennent de la documentation de
                <a target="_blank" href="http://naheulbeuk.com/">Naheulbeuk</a>
            </p>
            <p>
                Certaines images proviennent de la documentation de <a target="_blank" href="http://naheulbeuk.com/">Naheulbeuk</a>
            </p>
        </div>
        <h2>Info technique</h2>
        <div class="credits">
            <p>
                Frontend: <a target="_blank" href="https://angular.io">Angular</a>
                <br>
                Backend: <a target="_blank" href="https://www.microsoft.com/net/core">.NET Core</a>
                <br>
                Database: <a target="_blank" href="https://www.mysql.com/">MySQL</a>
                <br>
                Source: Github <a target="_blank" href="https://github.com/Socolin/Naheulbook">Frontend</a> - <a target="_blank" href="https://github.com/Socolin/NaheulbookBackend">Backend</a>
            </p>
        </div>
    </div>
</mat-sidenav-container>
